import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()

  return (
    <Layout {...props}>
      <Seo title='About Free Web Stack' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='About Free Web Stack'
          subheader='We are a group of free software enthusiasts who believe that quality content should be available for everyone. We publish thoughtful and straightforward tutorials on various topics related to web development and infrastructure management. Our goal is to make it easy for developers, designers, administrators, marketers or anyone else interested in the latest technologies found in this space - from server optimization to code frameworks - to find what they are looking for quickly and without any cost!'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
